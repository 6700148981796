<template>
  <div>
    <v-flex class="d-flex ma-2">
      <v-card width="50%">
        <v-tabs
            v-model="tab"
            background-color="transparent"
            color="basil"
            grow
        >
          <v-tab
              v-for="item in itemsFDayaDukungTypeComputed"
              :key="item.id"
          >
            {{ item.description }}
            
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item
              v-for="item in itemsFDayaDukungTypeComputed"
              :key="item.id"
          >
            <v-card
                color="basil"
                flat
            >
              <v-data-table
                  :headers="headers"
                  :items="itemsFDayaDukung"
              >

                <template v-slot:[`item.number`]="{ index }">
                  {{index+1}}
                </template>

                <template  v-slot:[`item.nilai1`]="{ item }">
                  <div>
                    {{ formatNumberString(item.nilai1) }}
                  </div>
                </template>

              </v-data-table>

            </v-card>
          </v-tab-item>
        </v-tabs-items>

      </v-card>

      <KpiMainMap style="width: 50%" class="ml-2 elevation-5"></KpiMainMap>

    </v-flex>
    <v-flex>
      <PublicFooter></PublicFooter>
    </v-flex>
  </div>
</template>

<script>

import KpiMainMap from "../public_infrastructure/InfrastructureMainMap";
import PublicFooter from "@/components/PublicFooter";
import FDayaDukungTypeService from "../..//services/apiservices/f-dayadukung-type-service";
import FDayadukungService from "@/services/apiservices/f-dayadukung-service";

export default {
  name: 'DayaDukungMain',
  components: { KpiMainMap, PublicFooter},
  data(){
    return{
      tab: null,
      selectedTabItem: undefined,
      itemsFDayaDukungType: [],
      itemsFDayaDukung:[],
      headers: [
        {
          text: 'No',
          value: 'number',
          width: '8%',
          sortable: false
        },
        { text: 'Description', value: 'description', width:'15%' },
        { text: 'Kab/Kota', value: 'city', width:'15%' },
        { text: 'Keterangan', value: 'nilai1', width:'15%' },
      ],

    }
  },
  watch:{
    tab: function (value){
      // console.log(this.itemsFInfrastructureTypeComputed[value].description)
      this.fetchData(this.itemsFDayaDukungTypeComputed[value])
    }
  },
  computed:{
    itemsFDayaDukungTypeComputed(){
      return this.itemsFDayaDukungType
    }
  },
  methods:{
    fetchParent(){
      FDayaDukungTypeService.getAllFDayaDukungTypePublic().then(
          response => {
            // console.log(JSON.stringify(response.data))

            this.itemsFDayaDukungType = response.data
          },
          error => {
            console.log(error.response)
          }
      )
    },

    fetchData(item){
      console.log(item.description)
      FDayadukungService.getAllFDayaDukungByTypePublic(item.id).then(
          response =>{
            this.itemsFDayaDukung = response.data
          },
          error =>{
            console.log(error)
          }
      )
    },

    formatNumberString(value){
      let valueStr = ""
      if (value/1000000000 >1) {
        valueStr = `${parseFloat(value / 1000000000).toLocaleString()} Milyar`
      }else if(value/1000000>1){
        valueStr = `${parseFloat(value / 1000000).toLocaleString()} Juta`
      }else if(value/1000>1){
        valueStr = `${parseFloat(value / 1000).toLocaleString()} Ribu`
      }else{
        valueStr = value.toString()
      }
      return valueStr
    },

  },

  mounted() {
    this.fetchParent()

  }

}
</script>

<style scoped>


</style>