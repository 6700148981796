<template>
  <DayaDukungMain></DayaDukungMain>
</template>

<script>
import DayaDukungMain from "@/components/public_dayadukung/DayaDukungMain";
export default {
  name: "DayaDukungView",
  components:{
    DayaDukungMain
  }
}
</script>

<style scoped>

</style>